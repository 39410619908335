var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100"},[_c('vue-custom-scrollbar',{staticClass:"app-content-padding flex-grow-1 overflow-hidden h-100"},[_c('ts-page-title',{attrs:{"title":_vm.$t('user.pageTitle'),"breadcrumb":[
								{ text: _vm.$t('home'), href: '/' },
								{
									text: _vm.$t('user.pageTitle'),
									href: '/admin/users'
								},
								{
									text: _vm.$t('edit'),
									active: true
								}
							]}}),_c('ts-loading-banner',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('ts-panel',[_c('ts-panel-wrapper',[_c('a-collapse',{attrs:{"bordered":false}},[_c('a-collapse-panel',{attrs:{"header":_vm.$t('user.specialPermission')}},[_c('Permission',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"col-md-4 tw-space-y-5"},[_c('UserType',{attrs:{"validate":_vm.errors},on:{"loading":function (value) { return _vm.loading = value; }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('UserAccount',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('UserRole',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])])],1),_c('div',{staticClass:"app-footer m-0 tw-flex tw-justify-end tw-space-x-3 tw-bg-white",attrs:{"id":"footer"}},[_c('ts-button',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'user' })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"waiting":_vm.waiting,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('update')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }