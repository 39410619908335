<template>
    <div class="d-flex flex-column h-100">
        <vue-custom-scrollbar
					class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
							:title="$t('user.pageTitle')"
							:breadcrumb="[
								{ text: $t('home'), href: '/' },
								{
									text: $t('user.pageTitle'),
									href: '/admin/users'
								},
								{
									text: $t('edit'),
									active: true
								}
							]"
            />
            <ts-loading-banner :loading="loading">
							<div class="row">
								<div class="col-md-8">
									<ts-panel>
										<ts-panel-wrapper>
											<a-collapse :bordered="false">
												<a-collapse-panel :header="$t('user.specialPermission')">
													<Permission v-model="model" />
												</a-collapse-panel>
											</a-collapse>
										</ts-panel-wrapper>
									</ts-panel>
								</div>
								<div class="col-md-4 tw-space-y-5">
										<UserType v-model="model" :validate="errors" @loading="(value) => loading = value" />
										<UserAccount v-model="model" :validate="errors" />
										<UserRole v-model="model" :validate="errors" />
								</div>
							</div>
            </ts-loading-banner>
        </vue-custom-scrollbar>
        <div
					id="footer"
					class="app-footer m-0 tw-flex tw-justify-end tw-space-x-3 tw-bg-white"
        >
          <ts-button @click.prevent="$router.push({ name: 'user' })">{{
            $t('cancel')
          }}</ts-button>
          <ts-button
            :waiting="waiting"
            @click.prevent="onUpdate"
            color="primary"
            >{{ $t('update') }}</ts-button
          >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import UserAccount from "./components/account.vue";
import UserRole from "./components/role.vue";
import Permission from './components/permision.vue'
import UserType from './components/user-type.vue'
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty } from 'lodash'

export default {
    name: "userEdit",
    components: {
        UserAccount,
        UserRole,
				Permission,
				UserType
    },
    data() {
			return {
				loading: false,
				waiting: false,
				waiting_new: false,
				errors: new Errors(),
				model: {
					user_type: 'admin',
					shop_id: undefined,
					agency_id: undefined,
					driver_id: undefined,
					employee_id: undefined,
					user_id: 0,
					user_name: "",
					email: "",
					roles: null,
					permissions: [],
					disable: false,
					password: null,
					password_confirmation: null
				}
			};
    },
    created() {
			AppOptions.appContentFullHeight = true;
			AppOptions.appContentClass = "p-0";
    },
		computed: {
			...mapState('auth/user', ['edit_data'])
		},
    methods: {
			...mapActions("auth/user", ['getUserRole', 'getPermissions', 'find', 'getFormViewData']),
			async fetchResource() {
				this.loading = true;
				await this.getUserRole({
					user_id: this.$route.params.id
				});
				await this.getPermissions({
					user_id: this.$route.params.id
				});
				await this.find(this.$route.params.id)
				this.setEditData()
				this.loading = false;
			},
			onUpdate() {
				this.errors = new Errors()
				this.waiting = true
				this.$store
					.dispatch('auth/user/update', {
						id: this.model.user_id,
						...this.model
					})
					.then(response => {
							this.$notify({ type: "success", text: response.message });
						this.$router.push({ name: 'user' })
					})
					.catch(error => {
						this.errors = new Errors(error.errors)
							this.$notify({ type: "error", text: error.message });
					})
					.finally(() => {
						this.waiting = false
					})
			},
			async setEditData () {
				if (!isEmpty(this.edit_data)) {
					await this.getFormViewData({
						index: this.edit_data.user_type,
						fnName: this.edit_data.user_type
					})
					this.model.user_type =  this.edit_data.user_type
					this.model.shop_id =  this.edit_data.shop_id
					this.model.agency_id =  this.edit_data.agency_id
					this.model.driver_id =  this.edit_data.driver_id
					this.model.employee_id =  undefined
					this.model.user_id = this.edit_data.user_id
					this.model.user_name = this.edit_data.user_name
					this.model.email = this.edit_data.email
					this.model.disable = this.edit_data.disable
					this.model.roles = this.edit_data.roles.length ? this.edit_data.roles[0].id : ''
					this.model.permissions = this.edit_data.permissions.map(el => el.id)
				}
			}
    },
    beforeRouteEnter(to, from, next) {
			next(vm => {
					vm.fetchResource();
			});
    },
    beforeRouteLeave(to, from, next) {
			AppOptions.appContentFullHeight = false;
			AppOptions.appContentClass = "";
			this.$store.commit('auth/user/SET_EDIT_DATA', {})
			next();
    }
};
</script>
